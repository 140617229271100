import React, { useState } from 'react';
import { Mail } from 'lucide-react';

const YahyaBio = () => {
  const [showHomeMessage, setShowHomeMessage] = useState(false);

  const handleHomeClick = (e) => {
    e.preventDefault();
    setShowHomeMessage(true);
    setTimeout(() => {
      setShowHomeMessage(false);
      window.location.href = "https://home.h.ya-ya.tech"; // Redirect to home URL after 2 seconds
    }, 2000);
  };

  return (
    <div className="min-h-screen w-full bg-gray-900 text-gray-100 flex flex-col justify-center items-center p-8">
      <div className="max-w-3xl w-full bg-gray-800 rounded-lg shadow-2xl overflow-hidden">
        <header className="bg-indigo-600 p-8 text-center">
          <h1 className="text-5xl font-bold text-white mb-2">yaḩya</h1>
          <h2 className="text-3xl text-indigo-200">devOops engine er</h2>
        </header>
        <nav className="bg-indigo-700 p-4">
          <div className="flex justify-center space-x-6 text-lg">
            <a href="https://dot.h.ya-ya.tech" rel="noopener noreferrer" target="_blank" className="text-indigo-100 hover:text-white transition-colors"> truth</a>
            <a href="/" onClick={handleHomeClick} className="text-indigo-100 hover:text-white transition-colors">home</a>
            <a href="https://merit.ya-ya.tech" rel="noopener noreferrer" target="_blank" className="text-indigo-100 hover:text-white transition-colors"> works</a>
            <a href="https://blog.yahya-abulhaj.dev/newsletter" className="text-indigo-100 hover:text-white transition-colors">fresh's</a>
          </div>
        </nav>
        {showHomeMessage && (
          <a href="https://home.h.ya-ya.tech" className="bg-green-500 text-white p-2 text-center block">
            disClaimer this page is my home
          </a>
        )}
        <main className="p-8">
        <section className="mb-8">
          <h6 className="text-lg text-yellow-400 mb-4">strucTure</h6>
          <p className="text-lg flex flex-wrap">
              <a href="https://learn.awsboot.com" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              yaCRUD
            </a>  
            <a href="https://yahpdf.computer-engineering.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              yahPDF
            </a>
            <a href="https://x.com/yaya2devops/status/1780147434993103286" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              yahTF
            </a> 
            <a href="https://az.9000.computer-engineering.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              azdash-
            </a>
            <a href="https://www.reddit.com/r/devops/comments/ucz66o/i_came_up_with_a_devops_joke_as_i_couldnt_find/" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              wehiring
            </a>
            <a href="https://raw.githubusercontent.com/yaya2devops/bachelor-guide/main/docs/pfa/assets/linedata/scott-best-intern-mentor-tbh.png" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              sCOTTsAVE
            </a> 
            <a href="https://github.com/yaya2devops/terraform-beginner-bootcamp-2023/issues/69" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              issue6⓽
            </a> 
            <a href="http://just.rise.ya-ya.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              certsPerks
            </a> 
            <a href="https://devsecops.computer-engineering.tech/definition/microsoft-influence" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              l⦿l,MSFT
            </a> 
            <a href="https://ranch.ya-ya.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              farmin'
            </a> 
            <a href="https://robots.ya-ya.tech" 
             className="text-blue-400 hover:text-blue-300 underline mr-4"
             target="_blank" 
             rel="noopener noreferrer">
            b⦿tzZz
            </a> 
            <a href="https://devops.ya-ya.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              fEEdME
            </a> 
            <a href="https://acquire.computer-engineering.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              hideIT
            </a> 
            <a href="https://az.ya-ya.tech/A-AZ900#01-motivation" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              AZ-999
            </a>
            <a href="https://raw.githubusercontent.com/yaya2devops/fakeeh-gcr/main/assets/beauty/prod.png" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              fakeeh
            </a> 
            <a href="https://archive.ya-ya.tech" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              archiv⦿
            </a> 
            <a href="https://istic.computer-engineering.tech/#/learntex" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              D⦿Cmaster
            </a>
            <a href="https://tformer.ya-ya.tech/journal/andrewxyaya.png" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              andreAwS 
            </a> 
            <a href="https://advisor-hh.ya-ya.tech/him/yaya2devops.pdf" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              tyKPMGca 
            </a> 
            <a href="https://covid.yahya-abulhaj.dev" 
               className="text-blue-400 hover:text-blue-300 underline mr-4"
               target="_blank" 
               rel="noopener noreferrer">
              covid-18
            </a> 
            <a href="https://mission.computer-engineering.tech" 
               className="text-blue-400 hover:text-blue-300 underline"
               target="_blank" 
               rel="noopener noreferrer">
              missi⦿n𝕏𝕏L
            </a> 
          </p>
        </section>

          <section className="mb-12">
            <h4 className="text-2xl text-pink-400 mb-4">current accountAbility</h4>
            <p className="text-lg">i am right now leveraging my current skills to master the leading cloud providers
            <br></br>
               aws azure and gcp for AI innovations.</p>
          </section>
          <section className="mb-12">
            <h1 className="text-4xl font-bold text-green-400 mb-4">about everything</h1>
            <p className="text-lg">this is a single reactjs component to show my expertise in the realm of devOps computing anc , infra ,$tructure engineering. <br></br>hopes you like it.</p>
          </section>
          <section>
            <h2 className="text-3xl font-semibold text-red-500 mb-4">help me</h2>
            <p className="text-lg mb-4">i have big problems<br></br> i will tell you them right now...</p>
            <p className="text-lg mb-4">she fixed them all<br></br>never mind it<br></br>its ok</p>
            <div className="relative inline-block">
              <img src="/assets/eliz.jpg" alt="Favorite anime character" className="rounded-lg shadow-lg mt-4" />
            </div>
          </section>
        </main>
        <footer className="bg-gray-800 p-6 flex justify-center space-x-6">
          <a href="mailto:dev@yahya-abulhaj.dev?subject=Let's work this out together&cc=addyourpartner@ourcollab" className="text-gray-400 hover:text-white transition-colors">
            <Mail size={30} />
          </a>
        </footer>
      </div>
    </div>
  );
};

export default YahyaBio;
